.accordion-item {
  box-shadow: $card-box-shadow;
}

.accordion-header {
  border-bottom: $card-border-width solid $card-border-color;
}

.accordion-button {
  &:not(.collapsed) {
    box-shadow: none;
  }
}
