// Card
.card {
  box-shadow: var(--#{$prefix}card-box-shadow);
}

.card-title {
  border-bottom: 1px solid $hr-color;
  color: $secondary;
  font-size: $font-size-sm;
  font-weight: 400;
  padding-bottom: .25rem;
}

.card-footer {
  --#{$prefix}card-cap-bg: #{$card-cap-footer-bg};
  --#{$prefix}card-border-color: #{$border-color};
}
