// Tables
.card-table {
  margin-bottom: 0;

  &.table-bordered {
    > thead > * {
      border-top-width: 0;
    }

    > :not(caption) > * > * {
      &:first-child {
        border-left-width: 0;
      }

      &:last-child {
        border-right-width: 0;
      }
    }

    > tbody > *:last-child {
      border-bottom-width: 0;
    }
  }

  .table-col-sm {
    width: 100px;
  }
}
