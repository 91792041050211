// Buttons
.btn-primary {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-border-color: #{$btn-primary-border-color};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-bg: #{$btn-primary-hover-bg};
  --#{$prefix}btn-hover-border-color: #{$btn-primary-hover-border-color};
  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$btn-primary-hover-bg};
  --#{$prefix}btn-active-border-color: #{$btn-primary-hover-border-color};
}

.btn-success {
  --#{$prefix}btn-color: #{$white};
  --#{$prefix}btn-border-color: #{$btn-success-border-color};
  --#{$prefix}btn-hover-color: #{$white};
  --#{$prefix}btn-hover-bg: #{$btn-success-hover-bg};
  --#{$prefix}btn-hover-border-color: #{$btn-success-hover-border-color};
  --#{$prefix}btn-active-color: #{$white};
  --#{$prefix}btn-active-bg: #{$btn-success-hover-bg};
  --#{$prefix}btn-active-border-color: #{$btn-success-hover-border-color};
  --#{$prefix}btn-disabled-color: #{$white};
}

.btn-danger {
  --#{$prefix}btn-border-color: #{$btn-danger-border-color};
  --#{$prefix}btn-hover-bg: #{$btn-danger-hover-bg};
  --#{$prefix}btn-hover-border-color: #{$btn-danger-hover-border-color};
  --#{$prefix}btn-active-bg: #{$btn-danger-hover-bg};
  --#{$prefix}btn-active-border-color: #{$btn-danger-hover-border-color};
}

.btn-default {
  --#{$prefix}btn-color: #{$body-color};
  --#{$prefix}btn-bg: #{$white};
  --#{$prefix}btn-border-color: #{$btn-default-border-color};
  --#{$prefix}btn-hover-color: #{$body-color};
  --#{$prefix}btn-hover-bg: #{$btn-default-hover-bg};
  --#{$prefix}btn-hover-border-color: #{$btn-default-hover-border-color};
  --#{$prefix}btn-active-color: #{$body-color};
  --#{$prefix}btn-active-bg: #{$btn-default-hover-bg};
  --#{$prefix}btn-active-border-color: #{$btn-default-hover-border-color};
}

.btn-xs {
  @include rfs($btn-font-size-sm, --#{$prefix}btn-font-size);

  line-height: 1.5;
  padding: .063rem .313rem;
}
