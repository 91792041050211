// Alert
.alert-warning {
  --#{$prefix}alert-color: #755600;
}

.alert-danger-light {
  --#{$prefix}alert-color: #{$alert-danger-light-color};
  --#{$prefix}alert-bg: #{$alert-danger-light-bg};
  --#{$prefix}alert-border-color: #{$alert-danger-light-border-color};
}
