@import 'react-toastify/scss/variables';
@import 'react-toastify/scss/toastContainer';
@import 'react-toastify/scss/toast';
// @import 'react-toastify/scss/theme';
// @import 'react-toastify/scss/closeButton';
@import 'react-toastify/scss/progressBar';
// @import 'react-toastify/scss/icons';

// entrance and exit animations
// @import 'react-toastify/scss/animations/bounce';
// @import 'react-toastify/scss/animations/zoom';
// @import 'react-toastify/scss/animations/flip';
@import 'react-toastify/scss/animations/slide';
// @import 'react-toastify/scss/animations/spin';

.Toastify {
  --toastify-toast-width: 50%;
  --toastify-toast-background: transparent;
  --toastify-toast-min-height: 58px;
  --toastify-font-family: #{$font-family-base};
}

.#{$rt-namespace}__toast-container {
  padding: 0;

  &--top-center {
    top: 0;
  }
}

.#{$rt-namespace}__toast {
  border-radius: 0;
  box-shadow: none;
  padding: 0;

  &-body {
    padding: 0;
  }

  .alert {
    --#{$prefix}alert-border-radius: 0;
    min-height: var(--toastify-toast-min-height);
  }
}
