// Color system

// gray-color-variables
$gray-200: #eee;
$gray-400: #ccc;
$gray-600: #777;
$gray-700: #555;
$gray-900: #2a3f54;

// color-variables
$blue:   #22a7e6;
$red:    #e33244;
$yellow: #fcc633;
$green:  #5cb85c;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3;

// theme-color-variables
$info: #31708f;

// Options
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: false;

// Body
// Settings for the `<body>` element.

$body-bg:    #f2f4f8;
$body-color: #333;

// Links
// Style anchor elements.

$link-decoration:       none;
$link-hover-color:      #1378a8;
$link-hover-decoration: underline;

// Paragraphs
// Style p element.

$paragraph-margin-bottom: .75rem;

// Components
// Define common padding and border radius sizes and more.

// border-variables
$border-color:             #ddd;
$border-color-translucent: rgba(0, 0, 0, .2);

// border-radius-variables
$border-radius:    .125rem;
$border-radius-sm: .125rem;

// Typography
// Font, line-height, and color for body text, headings, and more.

// font-variables
// $font-size-base affects the font size of the body text
$font-size-base: .875rem; // Assumes the browser default, typically `16px`
$font-size-sm:   .75rem;
$font-size-lg:   1.125rem;

$line-height-base: 1.428571429;

$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: .875rem;
$h6-font-size: .75rem;

// type-variables
$hr-color:   $gray-200;
$hr-opacity: 1;

// Tables
// Customizes the `.table` component with basic values, each used across all table variations.

// table-variables
$table-striped-bg: #f9f9f9;
$table-hover-bg:   #f5f5f5;

// Forms

// form-label-variables
$form-label-margin-bottom: .25rem;
$form-label-font-weight:   700;

// form-input-variables
$input-bg: #fff;

$input-color: $gray-700;

// input-group-variables
$input-group-addon-bg: $gray-200;

// Navbar

// navbar-variables
$navbar-padding-y: .25rem;

// navbar-theme-variables
$navbar-dark-color:       #9fb7c7;
$navbar-dark-hover-color: #fff;

// Pagination

// pagination-variables
$pagination-border-color:       $border-color;

$pagination-hover-bg:           $hr-color;
$pagination-hover-border-color: $border-color;

// Cards

// card-variables
$card-title-spacer-y: 1rem;
$card-border-color:   #eaeef1;
$card-border-radius:  2px;
$card-box-shadow:     0 1px 1px rgba(0, 0, 0, .05);
$card-cap-bg:         #fff;

// Accordion

// accordion-variables
$accordion-padding-x:                     1rem;
$accordion-bg:                            #fff;
$accordion-border-color:                  $card-border-color;

$accordion-button-padding-y:              .5rem;
$accordion-button-active-bg:              #fff;
$accordion-button-active-color:           $body-color;

$accordion-button-focus-box-shadow:       none;

// Badges

// badge-variables
$badge-padding-y:     .25em;
$badge-padding-x:     .6em;
$badge-border-radius: .25em;

// Modals

// scss-docs-start modal-variables
$modal-content-border-radius: $border-radius;

$modal-header-border-color: #e5e5e5;

$modal-footer-border-color: #e5e5e5;

$modal-md: 600px;

// Alerts
// Define alert colors, border radius, and padding.

// alert-variables
$alert-bg-scale:     0;
$alert-border-scale: 0;
$alert-color-scale:  0;

// Breadcrumbs

// breadcrumb-variables
$breadcrumb-font-size:      .813rem;
$breadcrumb-item-padding-x: .25rem;
$breadcrumb-margin-bottom:  1.25rem;
$breadcrumb-divider-color:  #999;
$breadcrumb-active-color:   $body-color;

// Close

// close-variables
$btn-close-opacity:       .2;
$btn-close-hover-opacity: .5;
