// Nav
.navbar-text .btn {
  --#{$prefix}btn-color: #{$navbar-dark-color};
  --#{$prefix}btn-hover-color: #{$navbar-dark-hover-color};
  --#{$prefix}btn-active-color: #{$navbar-dark-hover-color};

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
